import React from 'react';
import '../components/thanks.css';
import { Link } from 'gatsby';

export default function thanks() {
	return (
		<div className="thank-you">
			<p>
				<h1>Thank you!</h1>
				<h3>I will get back to you soon! 🎉</h3>
				<Link to="/" className="hahaha">
					Go back to the homepage
				</Link>
			</p>
		</div>
	);
}
